@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '../../../scss/variables.module.scss';


.canvas-generic-layout {
    width: 100%;
    height: 100% !important;
    //padding-left: 0px !important;
    //background-color: rgba(0, 0, 0, 0.3) !important;
    background-color: transparent !important;
}

.iw-progress>* {
    background-color: #1565c0 !important;
}

.iw-quick-dropdown div {
    border-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    background-color: transparent;
    padding: 0px;
    font-size: 1.2rem;
}

.MuiSlider-thumb {
    //background-color: #1565c0 !important;
}

.MuiLinearProgress-root {
    background-color: rgba(21, 101, 192, 0.1) !important;
    width: 100%;
}

#app-logo {
    height: 68px;
    position: absolute;
    // top: 3px;
    // left: 14px;
    top: -32px;
    left: -288px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    fill: inherit !important;
}

#joy-app-mini-logo {
    height: 44px;
    position: absolute;
//     top: 14px;
//     left: 14px;
}

#audio-import::file-selector-button {
    background-color: blue;
    color: white;
    border: none;
    padding: 5px;
    border-radius: 4px;
}

.MuiSlider-track {
    opacity: 0.1;
}

#quick-app-location-selector {
    width: 300px;
}

.canvas-generic-layout strong {
    position: relative;
    top: 5px;
}

.app {
    display: flex;
    flex-flow: column;
    height: 100%;

    footer {
        height: 30px;
        width: 100%;
        position: fixed;
        bottom: 0;
        text-align: center;

        .attribution {
            margin-top: 8px;
        }
    }

    section.main {
        position: absolute;
        top: 50px;
        bottom: 30px;
        width: 100%;
        overflow-y: auto;
    }
}

#appbar .MuiIconButton-root svg {
    //fill: rgba(0, 0, 0, 0.6);

}

input {
    color: rgb(51, 51, 51);
}

.searchBar {
    float: right;
    position: relative;
    top: -5px;
    overflow: hidden;
}

.searchBarInner {
    padding-top: 0px;
    margin-top: 0px;
    top: 13px;
    line-height: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    margin: auto;
    background-color: #F7F7F8;
    border-radius: 4px;
    overflow: hidden;
}

.searchBarIcon {
    padding-left: 5px;
    padding-right: 5px;
}

.searchBarInput {
    position: relative;
    top: -8px;
    margin-right: 5px;
    border: none;
    padding: 5px;
    color: white;
    background-color: #646464;
}

.mainNavWrapper {
    display: block;
    height: 100%;
}

.mainNavParent {
    display: flex;
    flex-direction: row;
    position: relative;
    bottom: 12px;
    height: 40px; // this const is set by the parent .AppBar
}

.mainNavItem {
    height: 40px;
    line-height: 40px;
    padding-bottom: 10px;
    align-self: flex-end;
    margin-right: 5px;
    font-weight: 500;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
    // z-index: $menu-z-index;
}


#simple-tabpanel-0 .MuiBox-root {
    padding: 0px !important;
}

#simple-tabpanel-1 .MuiBox-root {
    padding: 0px !important;
}

.navigation-button-group button {
    border-radius: 0px !important;
    border-bottom: none;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    background-color: transparent !important;
    font-weight: 500;
}

.navigation-button-group button:hover {
    color: rgb(30, 31, 33) !important;
    border-bottom: 2px solid;
    font-weight: 500;
}

.mainNavItem:hover {
    // opacity: 0.9;
    border-bottom: 2px solid;
    font-weight: 500;
    color: $main-button-highlight;
    border-color: $main-button-highlight;
}

.mainNavItemActive {
    border-bottom: 2px solid;
    font-weight: 600;
    opacity: 1.0;
}

.mainNavItemActive:hover {
    border-bottom: 2px solid;
    font-weight: 600;
    opacity: 1.0;
}

.mainNavItemNoNav {
    cursor: not-allowed !important;
    font-weight: 500;
}

.mainNavItemNoNav:hover {
    color: rgb(255, 255, 255) !important;
}
