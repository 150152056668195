.iw-notification-wrapper {
    display: flex;
    padding: 3px;
    margin-bottom: 20px;
    height: 80px;
}
  
  
.iw-notification-wrapper-content {
    padding: 5px;
}

.iw-notification-wrapper-context {
    display: block;
  position: absolute;
  right: 0px;
  bottom: -1px;
  font-size: 12px;
  width: 100%;
  background-color: #e3e3e3;
  border-bottom: 1px solid #8d8d8d;
  color: #424242;
}

.iw-notification-wrapper-context-button {
  background-color: #e3e3e3;
  margin-right: 5px;
  padding: 2px;
  float: right;
}

.iw-notification-wrapper-context-button-left {
  margin-left: 5px;
  padding: 2px;
  float: left;
}
