@import '../../../scss/variables.module.scss';

.custom-search-container {
  width: 100%;
  height: 38px;
  padding: 0px 5px; 
  border: 1px solid $border-grey;
  border-radius: 4px;
  display: flex;
  transition: width .5s ease-in-out;
  transition: border-color .1s ease-in-out;
  background-color: #fff;
  // color: white;
}

.expandable {
  width: 165px;
  transition: all .5s ease-in-out;
}

.custom-search-container:focus-within {
  width: 100%;
  border: 2px solid $main-button-highlight;
}

.custom-search-container:hover {
  border-color: $main-button-highlight;
}

.custom-search-input {
  width: 100%;
  border: none;
  border-radius: 15px;
  background-color: inherit;
  font-size: 15px;
  // color: white;
}

.custom-search-input:focus {
  outline: none;
  // color: white;
}
