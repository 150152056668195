.Draggable {
  height: 51px;
  transition: height 350ms;
  /* max-height: 51px;
  min-height: 51px;
  transition: max-height 350ms;
  transition: min-height 350ms; */
}

.Draggable.Expanded { 
  height: 325px; 
  /* min-height: 100px; 
  max-height: 325px;  */
}

