@import '../../../scss/variables.module.scss';

// Customizations to the tab style
.react-tabs__tav-list {
    margin: unset;
}

#transcript-parent {
    background-color: white !important;
}

#transcript-parent p {
    //word-break: keep-all !important;
}

.discovery-underline-hover:hover {
    background-color: $hover-option-color !important;
}

.feature-popup-titlebar {
    position: absolute;
    top: -2px;
    right: -2px;
}

.react-tabs__tab-list {
    line-height: 40px;
    height: 40px;
}
  
.react-tabs__tab {
    padding-bottom: 10px;
    margin-right: 5px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 40px;
    color: #0d0d0d;
    border-bottom: 5px solid #dcdcdc;
    background-color: unset;
    border: none;
    bottom: 0px !important;
}

.react-tabs__tab--selected {
    padding-bottom: 10px;
    margin-right: 5px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    line-height: 40px;
    color: #0d0d0d;
    border-bottom: 5px solid #dcdcdc;
    background-color: unset;
    border: none;
    bottom: 0px !important;
    color: #0D0D0D;
    background-color: #dcdcdc;
    border-bottom: 3px solid #0D0D0D;
    background-color: unset;
    border: none;
}

// Give the upload bar a nice backdrop
.uppy-ProgressBar {
    margin-top: 10px;
    background-color: #ededed;
    height: 25px;
    position: relative !important;
}

.discovery-finding-list-quote {
    font-style: italic;
}

.interviewDetailsRequiredFieldLabel {
    color: red;
    position: absolute;
    left: 65px;
}

.mediaTypeBadge {
    background-color: #dcdcdc !important;
    padding: 5px;
    font-size: 0.9em;
    border-radius: 5px;
    margin-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    bottom: 1px;
    border: 1px solid rgb(250, 250, 250);
}

.draftBadge {
    background-color: #dcdcdc !important;
    padding: 5px;
    font-size: 0.9em;
    border-radius: 5px;
    margin-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    bottom: 1px;
    border: 1px solid rgb(250, 250, 250);
}

.interviewBarButton {
    padding: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    bottom: 1px;
    top: -1px;
    margin-top: 6px !important;
    min-height: 30px !important;
    line-height: 30px !important;
}

.popover__content {
    position: fixed !important;
}

.finding-list-entry {
    display: -webkit-box; 
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

